import React, { PropsWithChildren } from 'react';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { getConfig } from '@yonomi/util-config';
import { setContext } from '@apollo/client/link/context';

interface ApolloProviderWithTokenAuthProps {
  children: React.ReactNode;
}

const Auth0ApolloProvider = ({ children }: PropsWithChildren<ApolloProviderWithTokenAuthProps>) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const httpLink = createHttpLink({
    uri: getConfig('REACT_APP_GRAPHQL_ENDPOINT'),
  });

  const authLink = setContext(async () => {
    const token = isAuthenticated ? await getAccessTokenSilently() : null;
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default Auth0ApolloProvider;
