import React, { useState } from 'react';
import { FederationConfigData, MutationResponse } from '../../../../models/DataTypes';
import { deleteFederationConfigGql, updateFederationConfigGql } from '../../../../helpers/gqlQueries';
import { useMutation } from '@apollo/client';
import {
  validateFederationClientId,
  validateFederationConfigDescription,
  validateFederationDiscoveryUrl,
  validateFederationName,
} from '@yonomi/util-validators';
import { trimObjectValues } from '../../../../helpers/dataUtils';
import { ConnectionType } from '../../../../models/ConnectionType';
import { FormControl, FormHelperText, MenuItem, Stack } from '@mui/material';
import { StyledTextField } from '../../../common/Theme';
import InfoToolTip from '../../../common/InfoToolTip';
import SubmitButtonWithLoader from '../../../common/SubmitButtonWithLoader';
import DeleteButton from '../../../common/DeleteButton';
import Box from '@mui/material/Box';

const UPDATE_FEDERATION_CONFIG = updateFederationConfigGql();
const DELETE_FEDERATION_CONFIG = deleteFederationConfigGql();

interface FederationConfigFormData {
  name: string;
  description: string;
  connectionType: string;
  federationDiscoveryUrl: string;
  federationClientId: string;
}

interface EditFederationConfigFormProps {
  federationConfig: FederationConfigData;
  onCompleted: (response: MutationResponse) => void;
  onError: (hasError: boolean, message: string) => void;
}

const CreateFederationConfigForm = (props: EditFederationConfigFormProps) => {
  //GQL
  const [
    updateFederationConfig,
    { error: updateFederationConfigError, loading: updatingFederationConfig },
  ] = useMutation(UPDATE_FEDERATION_CONFIG);
  const [
    deleteFederationConfig,
    { error: deleteFederationConfigError, loading: deletingFederationConfig },
  ] = useMutation(DELETE_FEDERATION_CONFIG);

  //States
  const [formData, setFormData] = useState<FederationConfigFormData>({
    name: props.federationConfig.name,
    description: props.federationConfig.description,
    connectionType: props.federationConfig.connectionType,
    federationDiscoveryUrl: props.federationConfig.federationDiscoveryUrl,
    federationClientId: props.federationConfig.federationClientId,
  });

  const [errors, setErrors] = useState<FederationConfigFormData>({
    name: '',
    description: '',
    connectionType: '',
    federationDiscoveryUrl: '',
    federationClientId: '',
  });

  //Handlers
  const validateForm = (data: FederationConfigFormData) => {
    let valid = true;
    const newErrors: Record<keyof FederationConfigFormData, string> = {
      name: '',
      description: '',
      connectionType: '',
      federationDiscoveryUrl: '',
      federationClientId: '',
    };

    try {
      validateFederationName(data.name);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Federation Config Name';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.name = errorMessage;
    }

    try {
      validateFederationConfigDescription(data.description);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Federation Config Description';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.description = errorMessage;
    }

    try {
      validateFederationDiscoveryUrl(data.federationDiscoveryUrl);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Federation Config Discovery URL';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.federationDiscoveryUrl = errorMessage;
    }

    try {
      validateFederationClientId(data.federationClientId);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Federation Config Client ID';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.federationClientId = errorMessage;
    }

    setErrors({ ...errors, ...newErrors });
    return valid;
  };

  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      connectionType: '',
      federationDiscoveryUrl: '',
      federationClientId: '',
    });
    setErrors({
      name: '',
      description: '',
      connectionType: '',
      federationDiscoveryUrl: '',
      federationClientId: '',
    });
  };

  const handleCompleted = (data: any) => {
    resetForm();
    props.onCompleted({ data, message: `${props.federationConfig.name} Federation Config successfully updated!` });
  };

  const handleDeleteCompleted = (data: any) => {
    props.onCompleted({ data, message: `${props.federationConfig.name} Federation Config successfully deleted!` });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormData({
      ...formData,
      connectionType: event.target.value as string,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedData = trimObjectValues(formData);
    setFormData(trimmedData);
    if (validateForm(trimmedData)) {
      try {
        props.onError(false, '');
        await updateFederationConfig({
          variables: {
            federationConfigId: props.federationConfig.fedConfigId,
            name: trimmedData.name,
            description: trimmedData.description,
            federationDiscoveryUrl: trimmedData.federationDiscoveryUrl,
            federationClientId: trimmedData.federationClientId,
          },
          onCompleted: (data) => handleCompleted(data),
        });
      } catch (error) {
        let errorMessage = `Failed to create Federation Config`;
        if (updateFederationConfigError) {
          errorMessage = `${errorMessage}: ${updateFederationConfigError.message}`;
        } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
        props.onError(true, errorMessage);
      }
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      props.onError(false, '');
      await deleteFederationConfig({
        variables: { federationConfigId: props.federationConfig.fedConfigId },
        onCompleted: (data) => handleDeleteCompleted(data),
      });
    } catch (error) {
      let errorMessage = `Failed to delete ${props.federationConfig.name} Federation Config`;
      if (deleteFederationConfigError) {
        errorMessage = `${errorMessage}: ${deleteFederationConfigError.message}`;
      } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
      props.onError(true, errorMessage);
    }
  };

  const isFormIncomplete: boolean =
    formData.name.trim() === '' ||
    formData.description.trim() === '' ||
    formData.connectionType.trim() === '' ||
    formData.federationDiscoveryUrl.trim() === '' ||
    formData.federationClientId.trim() === '';

  return (
    <form id={'form-edit-federation-config'} onSubmit={handleSubmit}>
      <FormControl>
        <StyledTextField
          required
          autoFocus
          fullWidth
          autoComplete="off"
          id="input-edit-federation-config-name"
          label="Federation Config Name"
          name="name"
          type="text"
          onChange={handleInputChange}
          value={formData.name}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.name}
        />
        {errors.name && (
          <FormHelperText id="name-helper" error={!!errors.name}>
            {errors.name}
          </FormHelperText>
        )}

        <StyledTextField
          required
          autoComplete="off"
          id="input-edit-federation-config-description"
          label="Description"
          name="description"
          type="text"
          onChange={handleInputChange}
          value={formData.description}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.description}
        />
        {errors.description && (
          <FormHelperText id="description-helper" error={!!errors.description}>
            {errors.description}
          </FormHelperText>
        )}

        <Stack direction={'row'} alignItems={'center'}>
          <Box>
            <StyledTextField
              required
              autoComplete="off"
              id="input-edit-federation-config-discovery-url"
              label="Federation Discovery URL"
              name="federationDiscoveryUrl"
              type="text"
              onChange={handleInputChange}
              value={formData.federationDiscoveryUrl}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.federationDiscoveryUrl}
            />
            {errors.federationDiscoveryUrl && (
              <FormHelperText id="discovery-url-helper" error={!!errors.federationDiscoveryUrl}>
                {errors.federationDiscoveryUrl}
              </FormHelperText>
            )}
          </Box>
          <InfoToolTip
            id={'tooltip-edit-federation-config-discovery-url'}
            sx={{ marginTop: '24px' }}
            isOffColor={true}
            placement="right"
            title={'Enter the URL of the discovery document of the Identity provider you want to connect with.'}
          />
        </Stack>

        <Stack direction={'row'} alignItems={'center'}>
          <Box>
            <StyledTextField
              required
              autoComplete="off"
              id="input-edit-federation-config-client-id"
              label="Federation Client ID"
              name="federationClientId"
              type="text"
              onChange={handleInputChange}
              value={formData.federationClientId}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.federationClientId}
            />
            {errors.federationClientId && (
              <FormHelperText id="federation-client-id-helper" error={!!errors.federationClientId}>
                {errors.federationClientId}
              </FormHelperText>
            )}
          </Box>
          <InfoToolTip
            id={'tooltip-edit-federation-config-client-id'}
            sx={{ marginTop: '24px' }}
            isOffColor={true}
            placement="right"
            title={
              "Client ID from the Identity provider you want to connect with. Obtaining the Client ID differs across providers. Please check your provider's documentation."
            }
          />
        </Stack>

        <StyledTextField
          required
          select
          id="input-edit-federation-config-connection-type"
          label="Connection Type"
          name="connectionType"
          onChange={handleSelectChange}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.connectionType}
          value={formData.connectionType}
        >
          {Object.values(ConnectionType).map((value) => (
            <MenuItem key={value} value={value}>
              {value.replaceAll('_', ' ')}
            </MenuItem>
          ))}
        </StyledTextField>

        <SubmitButtonWithLoader
          id={'btn-edit-federation-config-submit'}
          disabled={isFormIncomplete || updatingFederationConfig}
          label={'Save Changes'}
          loading={updatingFederationConfig}
        />

        <DeleteButton
          id={'btn-delete-federation-config'}
          resourceName={props.federationConfig.name}
          deleteButtonLabel={'Delete Federation Config'}
          deleteButtonOnClick={handleDeleteSubmit}
          deleteButtonDisabled={deletingFederationConfig || updatingFederationConfig}
          loading={deletingFederationConfig}
        />
      </FormControl>
    </form>
  );
};

export default CreateFederationConfigForm;
