import { Button, useTheme } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
import Loader from './Loader';

interface DeleteButtonProps {
  id?: string;
  resourceName: string;
  deleteButtonLabel: string;
  deleteButtonOnClick: () => void;
  deleteButtonDisabled: boolean;
  loading: boolean;
  sx?: React.CSSProperties;
}

const DeleteButton = ({
  id,
  deleteButtonLabel,
  deleteButtonOnClick,
  deleteButtonDisabled,
  loading,
  resourceName,
  sx,
}: DeleteButtonProps) => {
  const theme = useTheme();

  const handleDelete = () => {
    const shouldDelete = window.confirm(
      'Are you sure you want to delete this item?\n\n' + resourceName + '\n\n This action cannot be undone.'
    );

    if (shouldDelete) {
      deleteButtonOnClick();
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          id={id}
          variant="outlined"
          onClick={handleDelete}
          sx={{
            textTransform: 'none',
            width: 'fit-content',
            marginTop: '24px',
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
            '&:hover': {
              borderColor: theme.palette.error.main,
              backgroundColor: theme.palette.background.default,
            },
            ...sx,
          }}
          disabled={deleteButtonDisabled}
        >
          {deleteButtonLabel}
        </Button>
        {loading && (
          <Box id={'loader-delete-btn'} sx={{ marginTop: '24px', marginLeft: '16px' }} data-testid="loader">
            <Loader />
          </Box>
        )}
      </Box>
    </>
  );
};

export default DeleteButton;
