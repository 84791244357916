import { Box, TablePagination } from '@mui/material';

interface PaginationFooterProps {
  id?: string;
  page: number;
  count: number;
  rowsPerPage: number;
  pageChangeHandler: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleFirstPage?: () => void;
  handleLastPage?: () => void;
  handleNextPage?: () => void;
  handlePreviousPage?: () => void;
  rowsPerPageChangeHandler?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}
const PaginationFooter = ({
  id,
  page,
  count,
  rowsPerPage,
  pageChangeHandler,
  handleFirstPage,
  handleLastPage,
  handleNextPage,
  handlePreviousPage,
  rowsPerPageChangeHandler,
}: PaginationFooterProps) => {
  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    pageChangeHandler(event, newPage);
    const totalPages = count / rowsPerPage;
    if (newPage === 0) {
      // If navigating to the first page
      if (handleFirstPage) handleFirstPage();
    } else if (newPage === totalPages - 1) {
      // If navigating to the last page
      if (handleLastPage) handleLastPage();
    } else if (newPage < page) {
      // If navigating to the previous page
      if (handlePreviousPage) handlePreviousPage();
    } else if (newPage > page) {
      // If navigating to the next page
      if (handleNextPage) handleNextPage();
    }
  };
  return (
    <Box display="flex" justifyContent="end" alignItems="center">
      <TablePagination
        id={id}
        data-testid={id}
        page={page}
        count={count}
        showFirstButton
        showLastButton
        slotProps={{
          actions: {
            firstButton: {
              id: `${id}-first-page-btn`,
            },
            nextButton: {
              id: `${id}-next-page-btn`,
            },
            previousButton: {
              id: `${id}-previous-page-btn`,
            },
            lastButton: {
              id: `${id}-last-page-btn`,
            },
          },
        }}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        onPageChange={handlePageChange}
        onRowsPerPageChange={rowsPerPageChangeHandler}
        component="div"
      />
    </Box>
  );
};

export default PaginationFooter;
