import { Button } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import DevGroupTitleBox from '../DevGroupTitleBox';
import Typography from '@mui/material/Typography';
import InfoToolTip from '../../common/InfoToolTip';
import { Add } from '@mui/icons-material';
import SnackBar from '../../common/SnackBar';
import ActionModal from '../../common/ActionModal';
import {
  IntegrationData,
  MutationResponse,
  SimpleIntegrationData,
  SimpleM2MClientData,
  SimpleWebhookConfigData,
} from '../../../models/DataTypes';
import { listIntegrationsGql, listM2mCredsGql, listWebhookConfigsGql } from '../../../helpers/gqlQueries';
import { useRefetch } from '../../../RefetchContext';
import { useQuery } from '@apollo/client';
import IntegrationsTable from './tables/IntegrationsTable';
import CreateIntegrationForm from './forms/CreateIntegrationForm';
import CreateM2MClientForm from '../appClients/forms/M2MClients/CreateM2MClientForm';
import CreateWebhookConfigForm from '../webhooks/forms/CreateWebhookConfigForm';
import { IntegrationsInfo } from '../../infoData/IntegrationsInfo';
import { ContainerToolBar, ContentBox, MainContainer, StyledTableTitleRow } from '../../common/Theme';

const LIST_INTEGRATIONS = listIntegrationsGql();
const LIST_M2M_CREDS = listM2mCredsGql();
const LIST_WEBHOOKS = listWebhookConfigsGql();
const QUERY_MAX_FETCH_COUNT = 30;
const Integrations = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(searchParams);
  const devGroupId = searchParams.get('devGroupId') ?? '';
  const devGroupNameParam = searchParams.get('devGroupName') ?? '';
  const organizationId = searchParams.get('organizationId') || '';

  const onCompleted = useRef((response: MutationResponse) => {});

  //Auth0
  const { isAuthenticated } = useAuth0();

  //GQL
  const { registerRefetch } = useRefetch();
  const {
    data: integrationsData,
    error: listIntegrationsError,
    refetch: refetchIntegrations,
    loading: loadingIntegrations,
    fetchMore: fetchMoreIntegrations,
  } = useQuery(LIST_INTEGRATIONS, {
    skip: !isAuthenticated || !devGroupId,
    variables: { devGroupId, first: QUERY_MAX_FETCH_COUNT },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  registerRefetch('listIntegrations', refetchIntegrations);

  const { data: m2mClientsData, refetch: refetchM2MClients } = useQuery(LIST_M2M_CREDS, {
    skip: !isAuthenticated || !devGroupId,
    variables: { devGroupId, first: QUERY_MAX_FETCH_COUNT },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const { data: webhooksData, refetch: refetchWebhooks } = useQuery(LIST_WEBHOOKS, {
    skip: !isAuthenticated || !devGroupId,
    variables: { devGroupId, first: QUERY_MAX_FETCH_COUNT },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  //States
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState({ hasError: false, message: '' });
  const [devGroupName, setDevGroupName] = useState(devGroupNameParam);
  const [createIntegrationOpen, setCreateIntegrationOpen] = useState(false);
  const [selectedM2mClients, setSelectedM2mClients] = useState<SimpleM2MClientData[]>([]);
  const [selectedWebhooks, setSelectedWebhooks] = useState<SimpleWebhookConfigData[]>([]);

  const [createM2MClientOpen, setCreateM2MClientOpen] = useState(false);
  const [createWebhookOpen, setCreateWebhookOpen] = useState(false);

  const [lastIntegrationCursor, setLastIntegrationCursor] = useState('');

  //Handlers

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCreateIntegrationOpen = () => {
    setCreateIntegrationOpen(true);
  };

  const handleCreateIntegrationClose = () => {
    setSelectedM2mClients([]);
    setSelectedWebhooks([]);
    setCreateIntegrationOpen(false);
  };

  const handleCreateM2mClientOpen = () => {
    setCreateM2MClientOpen(true);
  };
  const handleCreateM2mClientClose = () => {
    setCreateM2MClientOpen(false);
  };

  const handleCreateWebhookOpen = () => {
    setCreateWebhookOpen(true);
  };
  const handleCreateWebhookClose = () => {
    setCreateWebhookOpen(false);
  };

  const delayedNavigate = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    navigate('/developer');
  };

  onCompleted.current = (response: MutationResponse) => {
    const data = response.data;
    setSnackbarMessage(`${response.message}`);
    setSnackbarOpen(true);
    if (data?.deleteDevGroup) {
      delayedNavigate();
    } else {
      if (data?.updateDevGroup) {
        setDevGroupName(data?.updateDevGroup?.name);
        queryParams.set('devGroupName', data?.updateDevGroup?.name);
        setSearchParams(queryParams.toString());
      } else if (data?.createIntegration) {
        handleCreateIntegrationClose();
      } else if (data?.createAppClient) {
        handleCreateM2mClientClose();
        refetchM2MClients();
        setSelectedM2mClients((prevSelectedM2mClients) => [
          ...prevSelectedM2mClients,
          { clientId: data?.createAppClient?.clientId, name: data?.createAppClient?.name },
        ]);
      } else if (data?.createWebhookConfig) {
        handleCreateWebhookClose();
        refetchWebhooks();
        setSelectedWebhooks((prevSelectedWebhooks) => [
          ...prevSelectedWebhooks,
          { webhookConfigId: data?.createWebhookConfig?.webhookConfigId, name: data?.createWebhookConfig?.name },
        ]);
      }
    }
  };

  const handleFetchMoreIntegrations = async () => {
    const hasNextPage = integrationsData?.listIntegrations?.pageInfo?.hasNextPage;
    if (hasNextPage) {
      fetchMoreIntegrations({
        variables: {
          devGroupId,
          first: QUERY_MAX_FETCH_COUNT,
          after: lastIntegrationCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            listIntegrations: {
              ...fetchMoreResult.listIntegrations,
              edges: [...prev.listIntegrations.edges, ...fetchMoreResult.listIntegrations.edges],
            },
          };
        },
      });
    }
  };

  const handleOnError = (hasError: boolean, message: string) => {
    setError({ hasError, message });
  };

  useEffect(() => {
    if (error.hasError) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }
  }, [error]);

  useEffect(() => {
    if (listIntegrationsError) {
      const errorMessage = listIntegrationsError?.message || 'Error processing request';
      setError({ hasError: true, message: errorMessage });
    }
  }, [listIntegrationsError]);

  useEffect(() => {
    if (integrationsData) {
      setLastIntegrationCursor(integrationsData.listIntegrations.pageInfo?.endCursor ?? '');
    }
  }, [integrationsData]);

  const integrations: IntegrationData[] = (integrationsData?.listIntegrations?.edges || []).map(
    (edge: any, index: number) => ({
      integrationId: edge.node.integrationId,
      devGroupId: edge.node.devGroupId,
      name: edge.node.name,
      description: edge.node.description,
      isPublic: edge.node.isPublic,
      createdAt: edge.node.createdAt,
      updatedAt: edge.node.updatedAt,
      devGroup: devGroupName,
      machineToMachineClients: (edge.node.m2mCredsIntegrationAssoc?.edges || []).map((edge: any) => {
        return { name: edge.node?.m2mCreds?.name, id: edge.node?.m2mCreds?.clientId };
      }),
      webhooks: (edge.node.webhookConfigIntegrationAssoc?.edges || []).map((edge: any) => {
        return { name: edge.node?.webhookConfig?.name, id: edge.node?.webhookConfig?.webhookConfigId };
      }),
      index: index,
    })
  );

  const allIntegrations: SimpleIntegrationData[] = integrations.map((integration: IntegrationData) => ({
    integrationId: integration.integrationId,
    name: integration.name,
  }));

  const allM2MClientData: SimpleM2MClientData[] = (m2mClientsData?.listAppClients?.edges || []).map((edge: any) => ({
    clientId: edge.node.clientId,
    name: edge.node.name,
  }));

  const allWebhookData: SimpleWebhookConfigData[] = (webhooksData?.listWebhookConfigs?.edges || []).map(
    (edge: any, index: number) => ({
      webhookConfigId: edge.node.webhookConfigId,
      name: edge.node.name,
    })
  );

  return isAuthenticated ? (
    <>
      <MainContainer>
        <ContainerToolBar />
        <Box component="div">
          <DevGroupTitleBox
            devGroupId={devGroupId}
            name={devGroupName}
            organizationId={organizationId}
            onCompleted={onCompleted}
            onError={handleOnError}
          />
          <ContentBox id={'integrations-section'}>
            <Box id={'integration-table-section'}>
              <StyledTableTitleRow
                direction="row"
                spacing={1}
                justifyContent="space-between"
                id={'integrations-header-row'}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" align="left" paragraph sx={{ marginBottom: 0 }}>
                    Integrations
                  </Typography>
                  <InfoToolTip id={'tooltip-integrations'} placement="right" title={<IntegrationsInfo />} />
                </Box>
                {!loadingIntegrations && (
                  <Button
                    id={'btn-add-integration'}
                    variant="contained"
                    onClick={handleCreateIntegrationOpen}
                    sx={{ paddingLeft: '10px', textTransform: 'none', height: '36px', alignSelf: 'center' }}
                  >
                    <Add
                      sx={{
                        width: '20px',
                        height: '20px',
                        marginRight: '8px',
                      }}
                    ></Add>
                    <Typography variant="button" sx={{ paddingTop: '2px' }}>
                      Add Integration
                    </Typography>
                  </Button>
                )}
              </StyledTableTitleRow>
              <Box>
                <IntegrationsTable
                  rows={integrations}
                  allM2mClientData={allM2MClientData}
                  allWebhookData={allWebhookData}
                  fetchMore={handleFetchMoreIntegrations}
                  openCreateM2mClientHandler={handleCreateM2mClientOpen}
                  openCreateWebhookHandler={handleCreateWebhookOpen}
                  onCompleted={onCompleted}
                  onError={handleOnError}
                  loading={loadingIntegrations}
                />
              </Box>
            </Box>
          </ContentBox>
          <SnackBar
            id={'notification-snackbar-integrations'}
            open={snackbarOpen}
            handleClose={handleSnackbarClose}
            message={snackbarMessage}
            severity={error.hasError ? 'error' : 'success'}
          />
        </Box>
      </MainContainer>
      <ActionModal
        id={'create-integration'}
        open={createIntegrationOpen}
        handleClose={handleCreateIntegrationClose}
        title={'Add Integration'}
        dialogContent={
          <CreateIntegrationForm
            devGroupId={devGroupId}
            allM2mClientData={allM2MClientData}
            allWebhookData={allWebhookData}
            openCreateM2mClientHandler={handleCreateM2mClientOpen}
            openCreateWebhookHandler={handleCreateWebhookOpen}
            onCompleted={onCompleted}
            onError={handleOnError}
            selectedM2mClients={selectedM2mClients}
            selectedWebhooks={selectedWebhooks}
          />
        }
      ></ActionModal>
      <ActionModal
        id={'create-m2m-creds'}
        open={createM2MClientOpen}
        handleClose={handleCreateM2mClientClose}
        title={'Add Machine to Machine Client'}
        dialogContent={
          <CreateM2MClientForm
            devGroupId={devGroupId}
            integrations={allIntegrations}
            onCompleted={onCompleted}
            onError={handleOnError}
            isIntegrationFlow={true}
          />
        }
      ></ActionModal>
      <ActionModal
        id={'create-webhook-config'}
        open={createWebhookOpen}
        handleClose={handleCreateWebhookClose}
        title={'Add Webhook'}
        dialogContent={
          <CreateWebhookConfigForm
            devGroupId={devGroupId}
            integrations={allIntegrations}
            onCompleted={onCompleted}
            onError={handleOnError}
            isIntegrationFlow={true}
          />
        }
      ></ActionModal>
    </>
  ) : null;
};

export default Integrations;
