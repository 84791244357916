import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';

interface MaskedTextFieldProps {
  value: string;
  maxLength?: number;
  sx?: React.CSSProperties;
  fixedWidth?: boolean;
  id?: string;
  primaryTypographyProps?: React.CSSProperties;
}

const MaskedTextField = ({ value, maxLength, sx, fixedWidth, id, primaryTypographyProps }: MaskedTextFieldProps) => {
  const [isVisible, setVisibility] = React.useState(false);

  const handleVisibilityToggle = () => {
    setVisibility(!isVisible);
  };

  const getFieldWidth = (): string => {
    if (!fixedWidth) return 'auto';
    const valueLength = value.length;
    if (valueLength <= 32) return '280px';
    if (valueLength <= 40) return '365px';
    if (valueLength <= 50) return '455px';
    if (valueLength <= 80) return '750px';
    return 'auto';
  };

  const maskedValue = '•'.repeat(fixedWidth ? value.length : 20).slice(0, maxLength);

  return (
    <Box display="flex" alignItems="center" style={{ ...sx }}>
      <Typography
        id={`text-${id}`}
        data-testid={`text-${id}`}
        sx={{
          width: getFieldWidth(),
          flex: fixedWidth ? 'none' : 1,
          marginRight: fixedWidth ? '16px' : undefined,
          ...primaryTypographyProps,
        }}
      >
        {isVisible ? value : maskedValue}
      </Typography>
      <IconButton
        onClick={() => handleVisibilityToggle()}
        edge="end"
        id={`icon-button-${id}`}
        data-testid={`icon-button-${id}`}
      >
        {isVisible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
      </IconButton>
    </Box>
  );
};

export default MaskedTextField;
