import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Paper,
  Stack,
  Switch,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { validateIntegrationDescription, validateIntegrationName } from '@yonomi/util-validators';
import {
  IntegrationData,
  MutationResponse,
  SimpleM2MClientData,
  SimpleWebhookConfigData,
} from '../../../../models/DataTypes';
import InfoToolTip from '../../../common/InfoToolTip';
import { trimObjectValues } from '../../../../helpers/dataUtils';
import {
  addM2MCredsToIntegrationGql,
  addWebhookConfigToIntegrationGql,
  deleteIntegrationGql,
  removeM2MCredsFromIntegrationGql,
  removeWebhookConfigFromIntegrationGql,
  updateIntegrationGql,
} from '../../../../helpers/gqlQueries';
import { StyledChip, StyledTextField } from '../../../common/Theme';
import SubmitButtonWithLoader from '../../../common/SubmitButtonWithLoader';
import DeleteButton from '../../../common/DeleteButton';
import Typography from '@mui/material/Typography';
import { Add, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { IntegrationsIsPublicInfo } from '../../../infoData/IntegrationsInfo';

const UPDATE_INTEGRATION = updateIntegrationGql();
const DELETE_INTEGRATION = deleteIntegrationGql();
const ADD_M2M_CLIENT_TO_INTEGRATION = addM2MCredsToIntegrationGql();
const REMOVE_M2M_CLIENT_FROM_INTEGRATION = removeM2MCredsFromIntegrationGql();
const ADD_WEBHOOK_TO_INTEGRATION = addWebhookConfigToIntegrationGql();
const REMOVE_WEBHOOK_FROM_INTEGRATION = removeWebhookConfigFromIntegrationGql();

interface EditIntegrationFormProps {
  integrationData: IntegrationData;
  allM2mClientData: SimpleM2MClientData[];
  allWebhookData: SimpleWebhookConfigData[];
  openCreateM2mClientHandler: () => void;
  openCreateWebhookHandler: () => void;
  onCompleted: (response: MutationResponse) => void;
  onError: (hasError: boolean, message: string) => void;
}

interface EditIntegrationFormData {
  name: string;
  description: string;
  isPublic: boolean;
  selectedM2mClients: SimpleM2MClientData[];
  selectedWebhooks: SimpleWebhookConfigData[];
}

const EditIntegrationForm = (props: EditIntegrationFormProps) => {
  const theme = useTheme();

  //GQL
  const [updateIntegration, { error: updateIntegrationError, loading: updatingIntegration }] = useMutation(
    UPDATE_INTEGRATION
  );
  const [deleteIntegration, { error: deleteIntegrationError, loading: deletingIntegration }] = useMutation(
    DELETE_INTEGRATION
  );

  const [addM2mToIntegration, { error: addM2mToIntegrationError, loading: addingM2mToIntegration }] = useMutation(
    ADD_M2M_CLIENT_TO_INTEGRATION
  );

  const [
    removeM2mFromIntegration,
    { error: removeM2mFromIntegrationError, loading: removingM2mFromIntegration },
  ] = useMutation(REMOVE_M2M_CLIENT_FROM_INTEGRATION);

  const [
    addWebhookToIntegration,
    { error: addWebhookToIntegrationError, loading: addingWebhookToIntegration },
  ] = useMutation(ADD_WEBHOOK_TO_INTEGRATION);

  const [
    removeWebhookFromIntegration,
    { error: removeWebhookFromIntegrationError, loading: removingWebhookFromIntegration },
  ] = useMutation(REMOVE_WEBHOOK_FROM_INTEGRATION);

  const selectedM2mClients: SimpleM2MClientData[] = props.integrationData.machineToMachineClients.map((m2mClient) => ({
    clientId: m2mClient.id,
    name: m2mClient.name,
  }));

  const selectedWebhooks: SimpleWebhookConfigData[] = props.integrationData.webhooks.map((webhook) => ({
    webhookConfigId: webhook.id,
    name: webhook.name,
  }));

  //States
  const [formData, setFormData] = useState<EditIntegrationFormData>({
    name: props.integrationData.name,
    description: props.integrationData.description,
    isPublic: props.integrationData.isPublic,
    selectedM2mClients: selectedM2mClients,
    selectedWebhooks: selectedWebhooks,
  });

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  //Handlers
  const validateForm = (data: EditIntegrationFormData) => {
    let valid = true;
    const newErrors = {
      name: '',
      description: '',
    };

    try {
      validateIntegrationName(data.name);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Integration Name';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.name = errorMessage;
    }

    try {
      validateIntegrationDescription(data.description);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Integration Description';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.description = errorMessage;
    }

    setErrors({ ...errors, ...newErrors });
    return valid;
  };

  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      isPublic: false,
      selectedM2mClients: [],
      selectedWebhooks: [],
    });
    setErrors({
      name: '',
      description: '',
    });
  };

  const handleCompleted = async (data: any) => {
    const allErrors: string[] = [];

    const currentM2mCredentials = new Set(
      props.integrationData.machineToMachineClients.map((m2mClient) => m2mClient.id)
    );
    const updatedM2mCredentials = new Set(formData.selectedM2mClients.map((m2mCred) => m2mCred.clientId));

    const currentWebhooks = new Set(props.integrationData.webhooks.map((webhook) => webhook.id));
    const updatedWebhooks = new Set(formData.selectedWebhooks.map((webhook) => webhook.webhookConfigId));

    // Add any newly selected m2m clients
    for (const m2mCred of formData.selectedM2mClients) {
      if (!currentM2mCredentials.has(m2mCred.clientId)) {
        try {
          // Add new M2M Client
          await addM2mToIntegration({
            variables: { integrationId: props.integrationData.integrationId, clientId: m2mCred.clientId },
          });
        } catch (error) {
          let errorMessage = `Unable to add M2M Client to ${props.integrationData.name}`;
          if (addM2mToIntegrationError) {
            errorMessage += `: ${addM2mToIntegrationError.message}`;
          } else if (error instanceof Error) {
            errorMessage += `: ${error.message}`;
          }
          allErrors.push(errorMessage);
        }
      }
    }
    // Remove any unselected m2m clients
    for (const m2mCred of props.integrationData.machineToMachineClients) {
      if (!updatedM2mCredentials.has(m2mCred.id)) {
        try {
          // Remove M2M Client
          await removeM2mFromIntegration({
            variables: { integrationId: props.integrationData.integrationId, clientId: m2mCred.id },
          });
        } catch (error) {
          let errorMessage = `Unable to remove M2M Client from ${props.integrationData.name}`;
          if (removeM2mFromIntegrationError) {
            errorMessage += `: ${removeM2mFromIntegrationError.message}`;
          } else if (error instanceof Error) {
            errorMessage += `: ${error.message}`;
          }
          allErrors.push(errorMessage);
        }
      }
    }
    //Add any newly selected webhooks
    for (const webhook of formData.selectedWebhooks) {
      if (!currentWebhooks.has(webhook.webhookConfigId)) {
        try {
          // Add new Webhook
          await addWebhookToIntegration({
            variables: {
              integrationId: props.integrationData.integrationId,
              webhookConfigId: webhook.webhookConfigId,
            },
          });
        } catch (error) {
          let errorMessage = `Unable to add Webhook to ${props.integrationData.name}`;
          if (addWebhookToIntegrationError) {
            errorMessage += `: ${addWebhookToIntegrationError.message}`;
          } else if (error instanceof Error) {
            errorMessage += `: ${error.message}`;
          }
          allErrors.push(errorMessage);
        }
      }
    }
    // Remove any unselected webhooks
    for (const webhook of props.integrationData.webhooks) {
      if (!updatedWebhooks.has(webhook.id)) {
        try {
          // Remove Webhook
          await removeWebhookFromIntegration({
            variables: { integrationId: props.integrationData.integrationId, webhookConfigId: webhook.id },
          });
        } catch (error) {
          let errorMessage = `Unable to remove Webhook from ${props.integrationData.name}`;
          if (removeWebhookFromIntegrationError) {
            errorMessage += `: ${removeWebhookFromIntegrationError.message}`;
          } else if (error instanceof Error) {
            errorMessage += `: ${error.message}`;
          }
          allErrors.push(errorMessage);
        }
      }
    }

    resetForm();

    if (allErrors.length > 0) {
      props.onError(true, allErrors.join(', '));
      return;
    }

    props.onCompleted({ data, message: `${props.integrationData.name} Integration successfully updated!` });
  };

  const handleDeleteCompleted = (data: any) => {
    props.onCompleted({ data, message: `${props.integrationData.name} Integration successfully deleted!` });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      isPublic: event.target.checked,
    });
  };

  const handleM2mClientSelect = (event: React.SyntheticEvent, value: SimpleM2MClientData[]) => {
    setFormData({
      ...formData,
      selectedM2mClients: value,
    });
  };

  const handleWebhooksSelect = (event: React.SyntheticEvent, value: SimpleWebhookConfigData[]) => {
    setFormData({
      ...formData,
      selectedWebhooks: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedData = trimObjectValues(formData);
    setFormData(trimmedData);
    if (validateForm(trimmedData)) {
      try {
        props.onError(false, '');
        await updateIntegration({
          variables: {
            integrationId: props.integrationData.integrationId,
            name: trimmedData.name,
            description: trimmedData.description,
            isPublic: formData.isPublic,
          },
          onCompleted: (data) => handleCompleted(data),
        });
      } catch (error) {
        let errorMessage = `Failed to update Integration`;
        if (updateIntegrationError) {
          errorMessage = `${errorMessage}: ${updateIntegrationError.message}`;
        } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
        props.onError(true, errorMessage);
      }
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      props.onError(false, '');
      await deleteIntegration({
        variables: { integrationId: props.integrationData.integrationId },
        onCompleted: (data) => handleDeleteCompleted(data),
      });
    } catch (error) {
      let errorMessage = `Failed to delete ${props.integrationData.name} Integration`;
      if (deleteIntegrationError) {
        errorMessage = `${errorMessage}: ${deleteIntegrationError.message}`;
      } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
      props.onError(true, errorMessage);
    }
  };

  const isFormIncomplete: boolean = formData.name.trim() === '';

  const isLoading =
    updatingIntegration ||
    deletingIntegration ||
    addingM2mToIntegration ||
    removingM2mFromIntegration ||
    addingWebhookToIntegration ||
    removingWebhookFromIntegration;

  return (
    <form id={'form-edit-integration'} onSubmit={handleSubmit}>
      <FormControl>
        <StyledTextField
          required
          autoFocus
          fullWidth
          autoComplete="off"
          id="input-edit-integration-name"
          label="Name"
          name="name"
          type="text"
          onChange={handleInputChange}
          value={formData.name}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.name}
        />
        {errors.name && (
          <FormHelperText id="name-helper" error={!!errors.name}>
            {errors.name}
          </FormHelperText>
        )}

        <StyledTextField
          required
          autoComplete="off"
          id="input-edit-integration-description"
          label="Description"
          name="description"
          type="text"
          onChange={handleInputChange}
          value={formData.description}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.description}
        />
        {errors.description && (
          <FormHelperText id="description-helper" error={!!errors.description}>
            {errors.description}
          </FormHelperText>
        )}

        <Stack direction={'row'} alignItems={'center'}>
          <Autocomplete
            sx={{ maxWidth: '350px' }}
            multiple
            value={formData.selectedM2mClients}
            id="input-edit-integration-m2m-creds"
            data-testid="m2m-creds-autocomplete"
            options={props.allM2mClientData}
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option.clientId === value.clientId}
            onChange={handleM2mClientSelect}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
                label="Machine to Machine Clients"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <StyledChip size={'small'} {...getTagProps({ index })} label={option.name} />
              ));
            }}
            noOptionsText={
              <Typography
                variant={'body1'}
                id="text-create-integration-no-m2m-creds"
                data-testid="text-create-integration-no-m2m-creds"
                sx={{ color: theme.palette.grey[400] }}
              >
                There are no Machine to Machine Clients
              </Typography>
            }
            PaperComponent={({ children }) => {
              return (
                <Paper>
                  {children}
                  <Button
                    id="btn-edit-integration-add-m2m-cred"
                    data-testid="btn-edit-integration-add-m2m-cred"
                    color="primary"
                    fullWidth
                    sx={{
                      justifyContent: 'flex-start',
                      textTransform: 'none',
                      color: theme.palette.primary.main,
                      display: 'flex',
                      alignItems: 'center',
                      pl: '24px',
                    }}
                    onMouseDown={() => {
                      props.openCreateM2mClientHandler();
                    }}
                  >
                    <Add sx={{ mr: '8px' }} /> Add New Machine to Machine Client
                  </Button>
                </Paper>
              );
            }}
          />
        </Stack>

        <Stack direction={'row'} alignItems={'center'}>
          <Autocomplete
            sx={{ maxWidth: '350px' }}
            multiple
            value={formData.selectedWebhooks}
            id="input-edit-integration-webhooks"
            data-testid="webhook-autocomplete"
            options={props.allWebhookData}
            isOptionEqualToValue={(option, value) => option.webhookConfigId === value.webhookConfigId}
            disableCloseOnSelect
            onChange={handleWebhooksSelect}
            getOptionLabel={(option) => option.name}
            noOptionsText={
              <Typography
                variant={'body1'}
                id="text-edit-integration-no-webhooks"
                data-testid="text-edit-integration-no-webhooks"
                sx={{ color: theme.palette.grey[400] }}
              >
                There are no Webhooks
              </Typography>
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
                label="Webhooks"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <StyledChip size={'small'} {...getTagProps({ index })} label={option.name} />
              ));
            }}
            PaperComponent={({ children }) => {
              return (
                <Paper>
                  {children}
                  <Button
                    id="btn-edit-integration-add-webhook"
                    data-testid="btn-edit-integration-add-webhook"
                    color="primary"
                    fullWidth
                    sx={{
                      justifyContent: 'flex-start',
                      textTransform: 'none',
                      color: theme.palette.primary.main,
                      display: 'flex',
                      alignItems: 'center',
                      pl: '24px',
                    }}
                    onMouseDown={() => {
                      props.openCreateWebhookHandler();
                    }}
                  >
                    <Add sx={{ mr: '8px' }} /> Add New Webhook
                  </Button>
                </Paper>
              );
            }}
          />
        </Stack>

        <Stack sx={{ marginTop: '16px' }} direction={'row'} alignItems={'center'}>
          <FormControlLabel
            sx={{ marginLeft: '0px', marginRight: '8px' }}
            control={
              <Switch
                id={'input-edit-integration-is-public'}
                checked={formData.isPublic}
                onChange={handleSwitchChange}
                inputProps={{ 'aria-label': 'make public' }}
              />
            }
            label="Make public"
          />
          <InfoToolTip
            id={'tooltip-edit-integration-is-public'}
            isOffColor={true}
            placement="right"
            title={<IntegrationsIsPublicInfo />}
          />
        </Stack>

        <SubmitButtonWithLoader
          id={'btn-edit-integration-submit'}
          disabled={isFormIncomplete || isLoading}
          label={'Save Changes'}
          loading={isLoading}
        />

        <DeleteButton
          id={'btn-delete-integration'}
          resourceName={props.integrationData.name}
          deleteButtonLabel={'Delete Integration'}
          deleteButtonOnClick={handleDeleteSubmit}
          deleteButtonDisabled={deletingIntegration || updatingIntegration}
          loading={deletingIntegration}
        />
      </FormControl>
    </form>
  );
};

export default EditIntegrationForm;
